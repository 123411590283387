import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import React from "react";
import {withStyles} from "@material-ui/core/styles";
import Popover from '@material-ui/core/Popover'

import styles from "./RoomStyles";
import config from "../common/config";

class Action extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            on : false,
            popoverAnchor : null,
            popoverText : null
        }

        this.popoverTimer = null

        this.onClick = this.onClick.bind(this)
    }

    getText(){
        return this.props.texts[Math.floor(Math.random() * this.props.texts.length)];
    }

    onClick(e){
        const on = !this.state.on
        const text = this.getText()

        this.setState({on : on})

        this.props.onClick(
            this.props.id,
            on ? text : '',
            on
        )

        if (on) {
            this.setState({
                popoverAnchor:e.currentTarget,
                popoverText:text,
            })

            this.popoverTimer = setTimeout(
                this.hidePopover.bind(this),
                config.yellCountdown
            )
        }
    }

    hidePopover() {
        this.popoverTimer = null
        this.setState({
            popoverAnchor : null,
            popoverText : null
        })
    }

    render() {
        return (
            <Box className={this.props.classes.actionBox}>
                <Button
                    variant="contained"
                    color={this.state.on ? 'primary' : 'default'}
                    className={this.props.classes.button}
                    onClick={this.onClick}
                    disabled={this.props.disabled}
                >
                    {this.state.on ? this.props.buttonFinish : this.props.buttonText}
                </Button>

                <Popover
                    open={!!this.state.popoverAnchor}
                    anchorEl={this.state.popoverAnchor}
                    anchorOrigin={{
                        vertical: 'center',
                        horizontal: 'left',
                    }}
                    transformOrigin={{
                        vertical: 'center',
                        horizontal: 'right',
                    }}
                    disableRestoreFocus
                    classes={{paper:this.props.classes.actionPopover}}
                >
                    {this.state.popoverText}
                </Popover>
            </Box>
        )
    }
}

class Yell extends React.Component {
    constructor(props) {
        super(props)
        this.onClick = this.onClick.bind(this)

        this.state = {
            popoverAnchor : null,
            popoverText : null
        }

        this.popoverTimer = null
    }

    getText(){
        return this.props.texts[Math.floor(Math.random()*this.props.texts.length)];
    }

    onClick(e){
        const text = this.getText()
        this.props.onClick(
            this.props.id,
            text
        )

        this.setState({
            popoverAnchor:e.currentTarget,
            popoverText:text,
        })

        this.popoverTimer = setTimeout(
            this.hidePopover.bind(this),
            config.yellCountdown
        )
    }

    hidePopover() {
        this.popoverTimer = null
        this.setState({
            popoverAnchor : null,
            popoverText : null
        })
    }

    render() {
        return (
            <Box className={this.props.classes.actionBox}>
                <Button
                    variant="contained"
                    color='secondary'
                    className={this.props.classes.actionButton}
                    onClick={this.onClick}
                    disabled={this.props.disabled}
                >
                    {this.props.buttonText}
                </Button>

                <Popover
                    open={!!this.state.popoverAnchor}
                    anchorEl={this.state.popoverAnchor}
                    anchorOrigin={{
                        vertical: 'center',
                        horizontal: 'left',
                    }}
                    transformOrigin={{
                        vertical: 'center',
                        horizontal: 'right',
                    }}
                    disableRestoreFocus
                    classes={{paper:this.props.classes.actionPopover}}
                >
                    {this.state.popoverText}
                </Popover>
            </Box>
        )
    }
}

Action = withStyles(styles)(Action)
Yell = withStyles(styles)(Yell)

export {Action, Yell}