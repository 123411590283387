const ActionsConfig = {
    actions : {
        afk : {
            ru : {
                button : 'AFK',
                buttonFinish : 'AFK',
                yells : [
                    'Отойду на чуток!',
                    'Скоро вернусь!',
                    'Ничего не бойтесь, никуда не уходите, я ща!',
                ]
            }
        },
        smoke : {
            ru : {
                button : 'ПОКУРЮ!',
                buttonFinish : 'ПОКУРИЛ!',
                yells : [
                    'Пойду покурю! 🚬',
                    'Надо перекурить! 🚬'
                ]
            }
        },
        refill : {
            ru : {
                button : 'НАЛЬЮ!',
                buttonFinish : 'НАЛИЛ!',
                yells : [
                    'Нужен рефилл! 🍺',
                    'Пора налить! 🍺',
                    'Пойду еще налью! 🍺'
                ]
            }
        },
        drunk : {
            ru : {
                button : 'ПЬЯН!',
                buttonFinish : 'ТРЕЗВ!',
                yells : [
                    'Чет я в говно! 🥴',
                    'Поднаебенился! 🥴',
                    'Походу я в хлам! 🥴',
                    '🥴🥴🥴🥴🥴 ик!'
                ]
            }
        },
        piss : {
            ru : {
                button : 'ПОССУ!',
                buttonFinish : 'ПОССАЛ!',
                yells : [
                    'Пойду поссу! 🚽',
                    'Пора пописить! 🚽',
                    'Ща вернусь! 🚽',
                    'Какой тут код от сортира? Пойду подбирать! 🚽'
                ]
            }
        },
        food : {
            ru : {
                button : 'ПОЕМ!',
                buttonFinish : 'ПОЕЛ!',
                yells : [
                    'Пойду пожру! 🍔',
                    'Пора отужинать! 🍔',
                    'Надо пожрать! 🍔',
                    'Пойду поем! 🍔',
                ]
            }
        },

    },
    yells : {
        cheers : {
            ru : {
                button : 'Выпьем!',
                yells : [
                    'Cheers! 🍺🍺🍺',
                    'Так выпьем же за это! 🍺🍺🍺',
                    'Na zdorovie! 🍺🍺🍺',
                    'Будем! 🍺🍺🍺',
                    'Ваше здоровье! 🍺🍺🍺',
                    'За приезд!! 🍺🍺🍺',
                    'За Дэми!!! 🍺🍺🍺'
                ]
            }
        },
        dich : {
            ru : {
                button : 'ДИЧЬ!',
                yells : [
                    'Вы несете какую то дичь!!!!!111 😠😠',
                    'Что вы блять несете?????!!!111 😠😠 ',
                    'Прекратите нести эту чушь!! 😠😠😠'
                ]
            }
        },
        zaebali : {
            ru : {
                button : 'ЗАЕБАЛИ!',
                yells : [
                    'Да вы заебали!! 😠😠😠',
                ]
            }
        },
        yachts : {
            ru : {
                button : 'ЯХТЫ!',
                yells : [
                    'Опять вы про свои яхты ебаные!!! ⛵😠',
                    'Да вы задрали со своими яхтами!!! ⛵😠'
                ]
            }
        },
        investors : {
            ru : {
                button : 'STONKS!',
                yells : [
                    'Инвесторы хуевы, блять 😠',
                    'Шортить, длиннить, заебали мамкины инвесторы!!',
                    'Да вы я гляжу тут все акулы рынка ценных бумаг?!',
                ]
            }
        },
        politics : {
            ru : {
                button : 'ПОЛИТИКИ!',
                yells : [
                    'Ну шо как вам политика с дивана?',
                    'Да харош про политику ну вы инвесторы или где?!',
                    'Ну пиздец вы эксперты оппозиционеры!!',
                ]
            }
        },
        work : {
            ru : {
                button : 'РАБОТА!',
                yells : [
                    'Харош про работу!!11 👨‍💻😠',
                    'Бля может на хуй про работу говоорить?? 👨‍💻😠'
                ]
            }
        },


        // estate : {
        //     ru : {
        //         button : 'ЖИЛЬЕ!',
        //         yells : [
        //             'Задрали недвижимость обсуждать!! 💰🏠😠😠',
        //             'Задрали со своими домами, буржуи, блять!!!11 💰🏠😠😠',
        //         ]
        //     }
        // },
        village : {
            ru : {
                button : 'КААШОЛТН!',
                yells : [
                    'Сельская молния: Бля парни я такие огурчики посадил!',
                    'Сельская молния: Народ, курочки такие яйки снесли...прелесть!',
                    'Сельская молния: Прикиньте у меня дома батареи топят!!!',
                    'Сельская молния: Блин у нас на районе столб поставили!!Прикол!',
                    'Сельская молния: Бля мне молочник во front porch насрал!',
                    'Сельская молния: Сверлю что хочу - свободная страна!!',
                    'Сельская молния: Бля, мне лисы весь сад засрали((но это коммьюнити!',
                ]
            }
        },
        serg : {
            ru : {
                button : 'СЕРЖ!',
                yells : [
                    'При Серже такого не было!',
                    'Да Серж бы тут навел порядок!',
                ]
            }
        },
        wrong : {
            ru : {
                button : 'ЕБАНУЛСЯ!',
                yells : [
                    'Да ты ебанулся!!',
                    'Ебанулся чтоль?',
                ]
            }
        },
        weather : {
            ru : {
                button : 'ПОГОДА!',
                yells : [
                    'Погода заебала! 🌧️😠',
                    'Бля, чо за погода?! 🌧️😠',
                ]
            }
        },
        fire : {
            ru : {
                button : 'ОГОНЬ!',
                yells : [
                    '🔥 Заебись! 🔥',
                    '🔥🔥🔥 Огонь! 🔥🔥🔥',
                    'Охуенно! 🔥',
                    '👍🏼 🔥 Зыкински! 👨🏼‍🦳',
                    '🔥 Збс! 🔥',
                    '🔥 Четко! 🔥',
                    'Белиссимо! 👍🏼',
                ]
            }
        },
        virus : {
            ru : {
                button : 'ВИРУС!',
                yells : [
                    'Коронавирус заебал! 🦠😠',
                    'Коронавирус уходи! 🥾🦠',
                    'Коронавирус заебал и вы вместе с ним! 🦠😠',
                ]
            }
        },
        dimon : {
            ru : {
                button : 'ДИМОН!',
                yells : [
                    'Бля, Димон!',
                ]
            }
        },
        ohuet : {
            ru : {
                button : 'ОХУЕТЬ!',
                yells : [
                    'Ну охуеть теперь!',
                    'Ахуеть!',
                ]
            }
        },
        physics : {
            ru : {
                button : 'ФИЗИКИ!',
                yells : [
                    'Физики блять! 🔬😠',
                    'Да вы задрали физики хуевы!! 🔬😠',
                ]
            }
        },
        carl : {
            ru : {
                button : 'CARL!',
                yells : [
                    'Carl please stop masturbating!',
                    'Carl your english is shit!',
                    'Carl dont touch it!',
                ]
            }
        },
    }
}

export default ActionsConfig