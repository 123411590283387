import React from 'react';
import {withStyles} from '@material-ui/core/styles';

import Box from  '@material-ui/core/Box'
import config from "../common/config";

import LocalVideo from "../common/LocalVideo";
import Controls from "./Controls";
import Participant from "./Participant";
import ActionsList from "./ActionsList";
import YellsList from "./YellsList";
import Chat from "./chat/Chat";

import ChatToggle from './chat/ChatToggle';
import GifToggle from './gif/GifToggle'

import styles from "./RoomStyles";
import GifPicker from "./gif/GifPicker";

const { largestRect } = require('rect-scaler');


//TODO
//заход по ссылке
//ошибки
class RoomPage extends React.Component {
    constructor(props) {
        super(props);
        this.classes = props.classes;

        this.state = {
            height : 0,
            width : 0,

            localVideoSrc : '',
            participants : {},

            camOn : this.props.camOn,
            micOn : this.props.micOn,

            dimensions : null,
            chatHidden : true,
            gifHidden : true,
        }

        this.resizeAllParticipants = this.resizeAllParticipants.bind(this)
        this.micClick = this.micClick.bind(this)
        this.camClick = this.camClick.bind(this)
        this.yellClick = this.yellClick.bind(this)
        this.actionClick = this.actionClick.bind(this)
        this.sendChatMessage = this.sendChatMessage.bind(this)
        this.sendGif = this.sendGif.bind(this)
        this.buttonSwitch = this.buttonSwitch.bind(this)
        this.muteParticipant = this.muteParticipant.bind(this)

        this.participantsObjects = {}

        this.chatRef = React.createRef()
        this.chatToggleRef = React.createRef()
    }

    resizeAllParticipants(e){
        const num = Object.values(this.state.participants).length
        if (num > 0) {
            this.recalculateAndSetDimensions(num)
        }
    }

    calcRect(num){
        const measures = this.measureElement('area')
        return largestRect(
            measures.width - 1,
            measures.height - 1,
            num,
            config.videoResolution.width,
            config.videoResolution.height
        );
    }

    measureElement (elementId) {
        const node = document.getElementById(elementId)
        return {
            width: node.offsetWidth,
            height: node.offsetHeight,
        }
    }

    componentDidMount() {
        window.addEventListener('resize', this.resizeAllParticipants)
        window.addEventListener('keydown', this.buttonSwitch, false)

        this.props.JanusWrapper.setCallbacks({
            onServerDown : this.handleServerDown.bind(this),
            onParticipantVideo : this.injectParticipantVideo.bind(this),
            onNewParticipant : this.handleNewParticipant.bind(this),
            onParticipantLeft : this.handleParticipantLeft.bind(this),
            onStatusRequest: this.handleStatusMessage.bind(this),
            onStatusUpdate: this.updateParticipantStatus.bind(this),
            onParticipantDataOpen: this.handleParticipantDataOpen.bind(this),
            onParticipantYell: this.handleParticipantYell.bind(this),
            onParticipantAction: this.handleParticipantAction.bind(this),
            onParticipantTalking: this.handleParticipantTalking.bind(this),
            onParticipantStopTalking: this.handleParticipantStopTalking.bind(this),
            onChatMessage: this.receiveChatMessage.bind(this),
            onGifMessage: this.receiveGif.bind(this),
            onParticipantMute: this.onMuteBySomeone.bind(this)
        })

        if (this.props.JanusWrapper.isPublishing()) {
            this.setState({
                localVideoSrc : window.URL.createObjectURL(this.props.JanusWrapper.getLocalStream())
            })

            this.props.JanusWrapper.listParticipants(
                this.props.roomId,
                this.handleParticipantsList.bind(this) // TODO error
            )
        }
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.resizeAllParticipants)
        window.removeEventListener('keydown', this.buttonSwitch)

        this.props.JanusWrapper.dropCallbacks()
        this.props.JanusWrapper.destroy()
    }

    handleParticipantsList(data){
        for (let participant of data.participants) {
            if (participant.id === this.props.myId) {
                continue
            }

            this.props.JanusWrapper.attachToParticipant(
                this.props.roomId,
                this.props.myId,
                this.props.myPvtId,
                participant.id,
                participant.display
            )
        }
    }

    handleNewParticipant(participantId, participantName, participantTalking){
        if (!this.state.participants[participantId]) {
            this.props.JanusWrapper.attachToParticipant(
                this.props.roomId,
                this.props.myId,
                this.props.myPvtId,
                participantId,
                participantName,
                participantTalking
            )
        } else {
            console.error('Participant already exists :' + participantId)
        }
    }

    handleParticipantLeft(participantId){
        const participants = this.state.participants
        delete participants[participantId]
        delete this.participantsObjects[participantId]
        this.setState({participants : participants})
        this.resizeAllParticipants()
    }

    injectParticipantVideo(participantId, participantName, video, audio, stream) {
        if (this.state.participants[participantId]) {
            return
        }

        const participants = this.state.participants
        const newRef = React.createRef()

        participants[participantId] = {
            id : participantId,
            ref : newRef
        }

        const newParticipantsNum = Object.values(participants).length

        this.participantsObjects[participantId] = <Participant
            key={participantId}
            id={participantId}
            ref={newRef}
            videoSrc={window.URL.createObjectURL(stream)}
            dimensions={this.calcRect(newParticipantsNum)}
            name={participantName}
            video={video}
            audio={audio}
            onMute={this.muteParticipant}
        />

        this.setState({
            participants : participants,
        })

        this.recalculateAndSetDimensions(newParticipantsNum)
    }

    muteParticipant(participantId){
        if (this.state.participants[participantId]) {
            this.props.JanusWrapper.muteParticipant(participantId)
            this.yellClick(
                'ne_pizdi',
                this.props.name + " просит завалить!"
            )
        }
    }

    onMuteBySomeone(data){
        if (this.props.myId === data.data.receiver_id) {
            this.switchMic(false)
        }
    }

    recalculateAndSetDimensions(num){
        const dimensions = this.calcRect(num)

        this.setState({
            dimensions : dimensions
        })

        for (let [, participant] of Object.entries(this.state.participants)) {
            participant.ref.current.setDimensions(dimensions)
        }

        return dimensions
    }

    handleServerDown(error){
        this.props.JanusWrapper.destroy()
        alert(error)
        window.location = '/';
    }

    micClick(){
        if (this.props.JanusWrapper.isPublishing()) {
            const micStatus = !this.state.micOn
            this.switchMic(micStatus)
        }
    }

    buttonSwitch(e){
        if (e.ctrlKey || e.metaKey) {
            if (e.key === 'd') {
                this.micClick()
                e.preventDefault()
            } else if (e.key === 'e') {
                this.camClick()
                e.preventDefault()
            }
        }
    }

    switchMic(on){
        //TODO error
        if (this.state.micOn === on) {
            return
        }
        this.props.JanusWrapper.changeAudioStatus(
            on,
            function () {
                this.setState({
                    micOn : on
                })
            }.bind(this)
        )
    }

    camClick(){
        if (this.props.JanusWrapper.isPublishing()) {
            const camStatus = !this.state.camOn
            this.switchCam(camStatus)
        }
    }

    switchCam (on) {
        if (this.state.camOn === on) {
            return
        }
        //TODO error
        this.props.JanusWrapper.changeVideoStatus(
            on,
            function () {
                this.setState({
                    camOn : on
                })
            }.bind(this)
        )
    }

    handleParticipantDataOpen(participantId){
        this.props.JanusWrapper.sendRequestStatusMessage(participantId)
        this.props.JanusWrapper.sendMediaStatusMessage()
    }

    handleStatusMessage(receiverId){
        if (receiverId === this.props.myId) {
            this.props.JanusWrapper.sendMediaStatusMessage() //TODO правильные данные по всем статусам
        }
    }

    updateParticipantStatus(data){
        if (this.state.participants[data.sender_id]) {
            this.state.participants[data.sender_id].ref.current.handleMediaStatus(data.data.video, data.data.audio)
        }
    }

    actionClick(key, text, on=true){
        this.props.JanusWrapper.sendDataMessage({
            message :'action',
            on : on,
            key : key,
            text : text
        })

        switch(key) {
            case 'afk':
                this.switchMic(!on)
                this.switchCam(!on)
                break;
            case 'piss':
                this.switchMic(!on)
                break;
        }
    }

    handleParticipantAction(data){
        if (this.state.participants[data.sender_id]) {
            this.state.participants[data.sender_id].ref.current.handleAction(data.data.key, data.data.on, data.data.text)
        }
    }

    yellClick(key, text){
        this.props.JanusWrapper.sendDataMessage({
            message :'yell',
            key : key,
            text : text
        })
    }

    handleParticipantYell(data){
        if (this.state.participants[data.sender_id]) {
            this.state.participants[data.sender_id].ref.current.handleYell(data.data.text)
        }
    }

    handleParticipantTalking(participantId, audioLevel){
        if (this.state.participants[participantId]) {
            this.state.participants[participantId].ref.current.startTalking()
        }
    }

    handleParticipantStopTalking(participantId){
        if (this.state.participants[participantId]) {
            this.state.participants[participantId].ref.current.stopTalking()
        }
    }

    sendChatMessage(id, text){
        this.props.JanusWrapper.sendDataMessage({
            message :'chat_message',
            id : id,
            text : text
        })
    }

    receiveChatMessage(data){
        if (this.state.participants[data.sender_id]) {
            const name = this.state.participants[data.sender_id].ref.current.getName()
            this.chatRef.current.receiveMessage(name, data.data.id, data.data.text)
            this.chatToggleRef.current.receiveMessage()
        }
    }

    chatToggle(){
        this.setState({chatHidden : !this.state.chatHidden})
        this.setState({gifHidden : true})
    }

    gifToggle(){
        this.setState({gifHidden : !this.state.gifHidden})
        this.setState({chatHidden : true})
    }

    //TODO should be integrated with chat fully but not partially
    sendGif(gif){
        this.props.JanusWrapper.sendDataMessage({
            message :'gif',
            url : gif.original.mp4,
            width : gif.original.width,
            height : gif.original.height,
        })

        this.chatRef.current.addGifMessageToList(this.props.name, gif.original.mp4)
    }

    receiveGif(data){
        if (this.state.participants[data.sender_id]) {
            this.state.participants[data.sender_id].ref.current.handleGif(
                data.data.url,
                data.data.width,
                data.data.height
            )

            const name = this.state.participants[data.sender_id].ref.current.getName()
            this.chatRef.current.receiveGif(name, data.data.url)
        }
    }

    render(){
        return (
            <Box className={this.classes.roomRoot}>
                <Box id='area' className={this.classes.roomContainer}>
                    <Box className={this.classes.participantsFlexBox}>
                        {Object.values(this.participantsObjects)}
                    </Box>
                </Box>

                <Chat
                    ref={this.chatRef}
                    myName={this.props.name}
                    onSendChatMessage={this.sendChatMessage}
                    hidden={this.state.chatHidden}
                />

                <GifPicker
                    wrapperClassName={this.classes.gifBox}
                    apiKey={config.giphyKey}
                    hidden={this.state.gifHidden}
                    onSelected={this.sendGif}
                    defaultSearch={config.giphyDefaultSearch}
                />

                <Box className={this.classes.roomSideBar}>
                    <LocalVideo
                        className={this.classes.roomLocalVideo}
                        src={this.state.localVideoSrc}
                    />

                    <Controls
                        micOn={this.state.micOn}
                        camOn={this.state.camOn}
                        micClick={this.micClick}
                        camClick={this.camClick}
                    />

                    <ActionsList onActionClick={this.actionClick}/>

                    <ChatToggle
                        chatToggle={this.chatToggle.bind(this)}
                        hidden={this.state.chatHidden}
                        ref={this.chatToggleRef}
                    />

                    <GifToggle
                        hidden={this.state.gifHidden}
                        gifToggle={this.gifToggle.bind(this)}
                    />

                    <YellsList onYellClick={this.yellClick}/>
                </Box>

            </Box>
        )
    }
}

export default withStyles(styles)(RoomPage)