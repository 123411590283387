const PUBLISHING = 'publishing'

class JanusWrapper {
    localStream
    localId
    JanusSession
    JanusPublishHandle
    JanusParticipantsHandles = {}
    publisherSdp
    Janus = window.Janus
    adapter = window.adapter
    opaqueId
    webRtcStatus

    constructor(props) {
        this.props = props || {}
        this.opaqueId = "alcochat-"+this.Janus.randomString(12)
    }

    setCallbacks(callbacks){
        this.props.callbacks = callbacks
    }

    dropCallbacks(){
        this.props.callbacks = null
    }

    init() {
        this.Janus.init(
            {
                debug: "all",
                callback : this.initCallback.bind(this)
            }
        )
    }

    getLocalStream() {
        return this.localStream
    }

    isPublishing(){
        return (this.webRtcStatus === PUBLISHING)
    }

    /**
     * после инита создаем соединение и сессию в янусе
     */
    initCallback() {
        this.JanusSession = new this.Janus({
            server: this.props.wssServer,
            success : this.attachToServer.bind(this),
            error : this.initError.bind(this)
        })
    }

    /**
     * присоединяемся к плагину комнат и создаем хэндлер для работы с ней
     */
    attachToServer() {
        this.JanusSession.attach(
            {
                plugin: "janus.plugin.videoroom",
                opaqueId: this.opaqueId,
                success: this.attachToServerSuccess.bind(this),
                error: this.joinError.bind(this),
                consentDialog: this.consentDialogStatusChange.bind(this),
                onmessage: this.handleSelfHandleMessage.bind(this),
                onlocalstream: this.localStreamAvailable.bind(this), //случается после configureLocalStreams
                mediaState: this.mediaStateChange.bind(this),
                webrtcState: this.webrtcStateChange.bind(this),
            }
        )
    }

    attachToServerSuccess(pluginHandle){
        this.Janus.log("Plugin attached! (" + pluginHandle.getPlugin() + ", id=" + pluginHandle.getId() + ")");
        this.Janus.log("  -- This is a publisher/manager");
        this.JanusPublishHandle = pluginHandle
        this.configureLocalStreams() //получаем локальный поток для ui то есть инициализируем webrtc локально
    }

    configureLocalStreams(){
        this.JanusPublishHandle.createOffer({
            // Add data:true here if you want to publish datachannels as well
            // Publishers are sendonly
            media: {
                audioRecv: false,
                videoRecv: false,
                audioSend: true,
                videoSend: true,
                data: true,
                video:{
                    height: {ideal: this.props.videoHeight},
                    width: {ideal: this.props.videoWidth},
                    // frameRate: {min : 30, max : 30}
                }
            },
            success: this.localStreamsConfigured.bind(this),
            error: this.webrtcError.bind(this)
        });

        this.props.callbacks.onServerInitialized()
    }

    /**
     * Получили локальный sdp - еще не отправляем на сервер. Хак чтобы получиь локальный поток
     * @param jsep
     */
    localStreamsConfigured(jsep){
        this.Janus.debug("Got local publisher SDP!");
        this.Janus.debug(jsep);
        this.publisherSdp = jsep
    }

    /**
     * Заходим в комнату, но не начинаем публиковать (то есть не видимы пока для других)
     * @param name
     * @param roomId
     */
    attachToRoom(name, roomId){
        this.JanusPublishHandle.send({
            "message": {
                "request": "join",
                "room": roomId,
                "ptype": "publisher",
                "display": name
            }
        })
    }

    /**
     * Зашли в комнату. вызывается из handleMessage
     * @param msg
     */
    attachToRoomSuccess(msg){
        this.Janus.log("Successfully joined room " + msg["room"] + " with ID " + msg["id"])
        this.localId = msg["id"]
        this.props.callbacks.onAttachToRoomSuccess(msg["room"], msg["id"], msg["private_id"]) //коллбэк ui контроллера
    }

    /**
     * Начинаем публиковать потоки (можем оба выключить) и становимся видимыми для других
     * @param video
     * @param audio
     */
    startPublishing(video = true, audio = true){
        // You can force a specific codec to use when publishing by using the
        // audiocodec and videocodec properties, for instance:
        // 		publish["audiocodec"] = "opus"
        // to force Opus as the audio codec to use, or:
        // 		publish["videocodec"] = "vp9"
        // to force VP9 as the videocodec to use. In both case, though, forcing
        // a codec will only work if: (1) the codec is actually in the SDP (and
        // so the browser supports it), and (2) the codec is in the list of
        // allowed codecs in a room. With respect to the point (2) above,
        // refer to the text in janus.plugin.videoroom.jcfg for more details
        this.JanusPublishHandle.send({
            message : {
                request: "publish",
                video: video,
                audio: audio,
                data : true
            },
            jsep : this.publisherSdp
        });
    }

    changeLocalAudioStatus(audioEnabled){
        if (audioEnabled) {
            this.JanusPublishHandle.unmuteAudio()
        } else {
            this.JanusPublishHandle.muteAudio()
        }
    }

    changeLocalVideoStatus(videoEnabled){
        if (videoEnabled) {
            this.JanusPublishHandle.unmuteVideo()
        } else {
            this.JanusPublishHandle.muteVideo()
        }
    }

    //TODO error
    changeAudioStatus(audioEnabled, successCallback) {
        const self = this
        this.changeLocalAudioStatus(audioEnabled)
        this.JanusPublishHandle.send({
            message : {
                request: "configure",
                audio: audioEnabled
            },
            success : function() {
                self.sendMediaStatusMessage()
                successCallback()
            }
        });
    }

    //TODO error
    changeVideoStatus(videoEnabled, successCallback) {
        const self = this
        this.changeLocalVideoStatus(videoEnabled)
        this.JanusPublishHandle.send({
            message : {
                request: "configure",
                video: videoEnabled
            },
            success : function() {
                self.sendMediaStatusMessage()
                successCallback()
            }
        });
    }

    muteParticipant(participantId) {
        this.sendDataMessage({
            message : 'mute',
            receiver_id : participantId
        })
    }

    sendDataMessage(data){
        const json = {
            sender_id : this.localId,
            data : data
        }
        this.JanusPublishHandle.data({
            data : JSON.stringify(json)
        })
    }

    /**
     * Так как юзаем вэбсокет то все взаимодействие со паблиш каналом асинхронное и обрабатывается тут
     * @param msg
     * @param jsep
     */
    handleSelfHandleMessage(msg, jsep){
        this.Janus.debug(" ::: Got a message (publisher) :::");
        this.Janus.debug(msg);

        const event = msg["videoroom"];

        if(event) {
            if(event === "joined") {
                this.attachToRoomSuccess(msg)
            } else if(event === "destroyed") {
                //TODO
            } else if(event === "talking") {
                this.props.callbacks.onParticipantTalking(msg['id'], msg['audio-level-dBov-avg'])
            } else if(event === "stopped-talking") {
                this.props.callbacks.onParticipantStopTalking(msg['id'])
            } else if(event === "event") {

                //Новый участник пришел
                if(msg["publishers"] !== undefined && msg["publishers"] !== null) {
                    this.Janus.debug("Got a list of available publishers/feeds:");
                    this.Janus.debug(msg["publishers"]);

                    for(let participant of msg["publishers"]) {
                        this.Janus.debug("  >> [" + participant.id + "] " + participant.display)
                        this.props.callbacks.onNewParticipant(participant.id, participant.display, false)
                    }

                    return
                }

                //кто то тикает
                if(msg["leaving"] !== undefined && msg["leaving"] !== null) {
                    // One of the publishers has gone away?
                    const participantId = msg["leaving"];
                    this.participantLeft(participantId)
                    return
                }

                //кто то тикает
                if(msg["unpublished"] !== undefined && msg["unpublished"] !== null) {
                    // One of the publishers has unpublished?
                    const participantId = msg["unpublished"];
                    if (participantId === 'ok') {
                        // That's us
                        this.JanusPublishHandle.hangup();
                        return;
                    }
                    this.participantLeft(participantId)
                    return
                }

                if(msg["configured"] !== undefined) {
                    if (msg["configured"] === 'ok') {
                        //TODO
                    } else {
                        //TODO
                    }
                } else if(msg["error"] !== undefined && msg["error"] !== null) {
                    switch (msg["error_code"]) {
                        case 426: //no such room
                            this.flowError(msg['error'], msg["error_code"])
                            break
                        case 432: //too many people
                            this.flowError(msg['error'], msg["error_code"])
                            break
                        case 434: //publishing already
                            this.flowError(msg['error'], msg["error_code"])
                            break
                        case 433: //non auth
                            this.flowError(msg['error'], msg["error_code"])
                            break
                        default:
                            this.flowError(msg['error'], msg["error_code"])
                    }
                }
            }
        }

        //случается в процессе установки webrtc соединения. вот так странно и вот тут
        if (jsep !== undefined && jsep !== null) {
            this.handleRemoteSdp(jsep, msg);
        }
    }

    handleRemoteSdp(jsep, msg) {
        this.Janus.debug("Handling SDP as well...");
        this.Janus.debug(jsep);

        // Check if any of the media we wanted to publish has
        // been rejected (e.g., wrong or unsupported codec)
        if (this.localStream && this.localStream.getAudioTracks() && this.localStream.getAudioTracks().length > 0 && !msg["audio_codec"]) {
            // Audio has been rejected
            this.webrtcError('Audio stream has been rejected due to incompatibility')
            return
        }

        if (this.localStream && this.localStream.getVideoTracks() && this.localStream.getVideoTracks().length > 0 && !msg["video_codec"]) {
            // Video has been rejected
            this.webrtcError('Video stream has been rejected due to incompatibility')
            return
        }

        this.JanusPublishHandle.handleRemoteJsep({jsep: jsep});
    }

    localStreamAvailable(stream) {
        const videoTracks = stream.getVideoTracks()
        const audioTracks = stream.getAudioTracks()

        if(videoTracks === null || videoTracks === undefined || videoTracks.length === 0) {
            this.webrtcError('No video tracks. Maybe access issues or no devices. You shall not pass. No money no honey!')
            return
        }

        if(audioTracks === null || audioTracks === undefined || audioTracks.length === 0) {
            this.webrtcError('No audio tracks. Maybe access issues or no devices. You shall not pass. No money no honey!')
            return
        }

        this.props.callbacks.onLocalVideo(stream) //вызов коллбэка на апдейт ui
        this.localStream = stream
    }

    mediaStateChange(medium, on){
        this.Janus.log("Janus " + (on ? "started" : "stopped") + " receiving our " + medium);
    }

    webrtcStateChange(on){
        this.Janus.log("Janus says our WebRTC PeerConnection is " + (on ? "up" : "down") + " now");
        if (!on) {
            this.webrtcError('WebRTC connection is down')
        } else {
            this.webRtcStatus = PUBLISHING
            this.props.callbacks.onConnectionUp() //коллбэк ui контроллера
        }
    }

    listParticipants(roomId, successCallback){
        this.JanusPublishHandle.send(
            {
                message : {
                    request: "listparticipants",
                    room: roomId
                },
                success : successCallback
            }
        );
    }

    //--------------------------------------------------------------------------------

    //TODO переделать на хуй все к чертям
    attachToParticipant(roomId, myId, myPvtId, participantId, participantName) {
        const self = this

        this.JanusSession.attach({
            plugin: "janus.plugin.videoroom",
            opaqueId: this.opaqueId,
            success: function(pluginHandle) {
                pluginHandle.simulcastStarted = false
                self.Janus.log("Plugin attached! (" + pluginHandle.getPlugin() + ", id=" + pluginHandle.getId() + ")")
                self.Janus.log("  -- This is a subscriber")

                pluginHandle.send({
                    "message": {
                        "request": "join",
                        "room": roomId,
                        "ptype": "subscriber",
                        "feed": participantId,
                        "private_id": myPvtId
                    }
                })

                self.JanusParticipantsHandles[participantId] = pluginHandle
            },
            error: function(error) { //TODO error
                self.Janus.error("  -- Error attaching plugin...", error);
            },

            onmessage: function(msg, jsep) {
                self.Janus.debug(" ::: Got a message (subscriber) :::");
                self.Janus.debug(msg);
                var event = msg["videoroom"];
                self.Janus.debug("Event: " + event);
                if(msg["error"] !== undefined && msg["error"] !== null) {
                    // bootbox.alert(msg["error"]); //TODO
                } else if(event !== undefined && event !== null) {
                    if(event === "attached") {
                        // Subscriber created and attached
                        //TODO
                        self.Janus.log("Successfully attached to feed " + participantId);
                    } else {
                        // What has just happened?
                    }
                }
                if(jsep !== undefined && jsep !== null) {
                    self.Janus.debug("Handling SDP as well...");
                    self.Janus.debug(jsep);
                    // Answer and attach
                    self.JanusParticipantsHandles[participantId].createAnswer(
                        {
                            jsep: jsep,
                            // Add data:true here if you want to subscribe to datachannels as well
                            // (obviously only works if the publisher offered them in the first place)
                            media: { // We want recvonly audio/video
                                audioSend: false,
                                videoSend: false,
                                data: true
                            },
                            success: function(jsep) {
                                self.Janus.debug("Got SDP!");
                                self.Janus.debug(jsep);
                                self.JanusParticipantsHandles[participantId].send({
                                    "message": {
                                        "request": "start",
                                        "room": roomId
                                    },
                                    "jsep": jsep
                                });
                            },
                            error: function(error) {
                                self.Janus.error("WebRTC error:", error); //TODO error
                            }
                        });
                }
            },
            webrtcState: function(on) {
                self.Janus.log("Janus says this WebRTC PeerConnection (feed #" + participantId + ") is " + (on ? "up" : "down") + " now")
            },
            ondataopen: function(){
                self.props.callbacks.onParticipantDataOpen(participantId)
            },
            onremotestream: function(stream) {
                self.Janus.log("Remote feed #" + participantId);

                var videoTracks = stream.getVideoTracks();
                var audioTracks = stream.getAudioTracks();

                var video = true
                if(videoTracks === null || videoTracks === undefined || videoTracks.length === 0) {
                    video = false
                }

                var audio = true
                if(audioTracks === null || audioTracks === undefined || audioTracks.length === 0) {
                    audio = false
                }

                self.props.callbacks.onParticipantVideo(participantId, participantName, video, audio, stream)
            },
            ondata : self.handleDataMessage.bind(self)
        })
    }

    participantLeft(participantId) {
        if (this.JanusParticipantsHandles[participantId]) {
            this.Janus.log("Publisher left: " + participantId);
            const pluginHandle = this.JanusParticipantsHandles[participantId]
            pluginHandle.detach()
            delete this.JanusParticipantsHandles[participantId]
            this.props.callbacks.onParticipantLeft(participantId)
        }
    }

    handleDataMessage(msg){
        const json = JSON.parse(msg)

        if (json.data.message === 'status_request') {
            this.props.callbacks.onStatusRequest(json.data.receiver_id)
            return
        }

        if (json.data.message === 'status') {
            this.props.callbacks.onStatusUpdate(json)
            return
        }

        if (json.data.message === 'yell') {
            this.props.callbacks.onParticipantYell(json)
            return
        }

        if (json.data.message === 'action') {
            this.props.callbacks.onParticipantAction(json)
            return
        }

        if (json.data.message === 'chat_message') {
            this.props.callbacks.onChatMessage(json)
            return
        }

        if (json.data.message === 'gif') {
            this.props.callbacks.onGifMessage(json)
            return
        }

        if (json.data.message === 'mute') {
            this.props.callbacks.onParticipantMute(json)
            return
        }
    }

    sendRequestStatusMessage(receiverId){
        this.sendDataMessage({
            message : 'status_request',
            receiver_id : receiverId
        })
    }

    sendMediaStatusMessage(){
        this.sendDataMessage({
            message :'status',
            audio : !this.JanusPublishHandle.isAudioMuted(),
            video : !this.JanusPublishHandle.isVideoMuted(),
        })
    }

    //--------------------------------------------------------------------------------

    flowError (errorText, errorNum){
        this.Janus.error("Flow error:", errorText);
        this.props.callbacks.onServerDown(errorText)
    }

    webrtcError(error){
        this.Janus.error("WebRTC error:", error);
        this.props.callbacks.onServerDown(error)
    }

    initError(error) {
        this.Janus.error(error)
        this.props.callbacks.onServerDown(error)
    }

    joinError(error){
        this.Janus.error("  -- Error attaching plugin...", error);
        this.props.callbacks.onServerDown(error)
    }

    consentDialogStatusChange(on){
        this.Janus.log("Consent dialog should be " + (on ? "on" : "off") + " now");
    }

    destroy(){
        this.JanusSession.destroy({
            unload: true,
            notifyDestroyed: false
        })
    }

    //TODO errors
    async getRoomsList(){
        const listRespone = await fetch(
            this.props.adminServer,
            {
                'method' : 'POST',
                body: JSON.stringify({
                    admin_secret: this.props.adminSecret,
                    janus: "message_plugin",
                    plugin: "janus.plugin.videoroom",
                    request: {request: "list"},
                    transaction: this.Janus.randomString(12)
                })
            }
        )
        return await listRespone.json()
    }

    async getParticipantsList(roomId){
        const listRespone = await fetch(
            this.props.adminServer,
            {
                'method' : 'POST',
                body: JSON.stringify({
                    admin_secret: this.props.adminSecret,
                    janus: "message_plugin",
                    plugin: "janus.plugin.videoroom",
                    request: {request: "listparticipants", room:parseInt(roomId)},
                    transaction: this.Janus.randomString(12)
                })
            }
        )
        return await listRespone.json()
    }
}

export default JanusWrapper;

/*
#define JANUS_VIDEOROOM_ERROR_UNKNOWN_ERROR		499
#define JANUS_VIDEOROOM_ERROR_NO_MESSAGE		421
#define JANUS_VIDEOROOM_ERROR_INVALID_JSON		422
#define JANUS_VIDEOROOM_ERROR_INVALID_REQUEST	423
#define JANUS_VIDEOROOM_ERROR_JOIN_FIRST		424
#define JANUS_VIDEOROOM_ERROR_ALREADY_JOINED	425
#define JANUS_VIDEOROOM_ERROR_NO_SUCH_ROOM		426
#define JANUS_VIDEOROOM_ERROR_ROOM_EXISTS		427
#define JANUS_VIDEOROOM_ERROR_NO_SUCH_FEED		428
#define JANUS_VIDEOROOM_ERROR_MISSING_ELEMENT	429
#define JANUS_VIDEOROOM_ERROR_INVALID_ELEMENT	430
#define JANUS_VIDEOROOM_ERROR_INVALID_SDP_TYPE	431
#define JANUS_VIDEOROOM_ERROR_PUBLISHERS_FULL	432
#define JANUS_VIDEOROOM_ERROR_UNAUTHORIZED		433
#define JANUS_VIDEOROOM_ERROR_ALREADY_PUBLISHED	434
#define JANUS_VIDEOROOM_ERROR_NOT_PUBLISHED		435
#define JANUS_VIDEOROOM_ERROR_ID_EXISTS			436
#define JANUS_VIDEOROOM_ERROR_INVALID_SDP		437
 */