import React from "react";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import TextField from "@material-ui/core/TextField";

class NameInput extends React.Component {
    constructor(props) {
        super(props)
        this.props = props

        this.state = {
            name : this.props.name,
            valid : !!this.props.name,
        }

        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(e){
        const name = e.target.value
        const valid = name.length !== 0;

        this.setState({
            name : name,
            valid : valid
        })

        this.props.handleNameChange(name, valid)
    }

    render () {
        return (
            <List component="nav">
                <ListItem>
                    <TextField
                        required
                        id="nameInput"
                        label="Назовись"
                        defaultValue={this.props.name}
                        error={!this.state.valid}
                        onChange={this.handleChange}
                        disabled={!this.props.inputEnabled}
                    />
                </ListItem>
            </List>
        )
    }
}

export default NameInput