import React from "react";
import Box from "@material-ui/core/Box";

class LocalVideo extends React.Component {
    constructor(props) {
        super(props)
        this.props = props
        this.id = 'LocalVideo'
    }

    componentDidUpdate() {
        const el = document.getElementById(this.id)
        if (el) {
            el.setAttribute('muted', 'muted') //FIXME хак тк ебаный реакт не понимает muted нормально
            el.style.transform = 'scale(-1, 1)' //to mirror video like google meets does
        }
    }

    render(){
        if (!this.props.src) {
            return (
                <Box className={this.props.className}>
                    Камера заводится...
                </Box>
            )
        } else {
            return (
                <video
                    id={this.id}
                    className={this.props.className}
                    autoPlay
                    playsInline
                    muted='muted'
                    src={this.props.src}
                />
            )
        }
    }
}

export  default LocalVideo