import Box from "@material-ui/core/Box";
import React from "react";
import styles from "../RoomStyles";
import {withStyles} from "@material-ui/core/styles";

class ChatGifMessage extends React.Component {
    constructor(props) {
        super(props)
        this.classes = props.classes
    }

    render() {
        return (
            <Box className={this.classes.chatMessage}>
                <Box className={this.classes.chatMessageName}>{this.props.name}</Box>
                <Box className={this.classes.chatMessageGif}>
                    <video className={this.classes.chatMessageGifImage} autoPlay loop>
                        <source src={this.props.url} type='video/mp4'/>
                    </video>
                </Box>
            </Box>
        )
    }
}

export default withStyles(styles)(ChatGifMessage)