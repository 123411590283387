import React from 'react';
import ActionsConfig from '../common/ActionsConfig'
import {Yell} from "./Action";

class YellsList extends React.Component {
    constructor(props) {
        super(props)

        this.locale = 'ru' //TODO

        this.yellClick = this.yellClick.bind(this)

        this.yellsTimerId = null

        this.state = {
            yellsDisabled : false
        }
    }

    yellClick(key, text){
        this.yellsLock()
        this.props.onYellClick(key, text)
    }

    yellsLock(){
        this.setState({yellsDisabled:true})
        this.yellsTimerId = setTimeout(
            this.yellsUnlock.bind(this),
            2000
        )
    }

    yellsUnlock() {
        clearTimeout(this.yellsTimerId)
        this.setState({yellsDisabled:false})
    }

    render() {
        var yells = []
        for (let [key, action] of Object.entries(ActionsConfig.yells)){
            const conf = action[this.locale]
            yells.push(
                <Yell
                    key={key}
                    id={key}
                    type="yell"
                    buttonText={conf.button}
                    texts={conf.yells}
                    onClick={this.yellClick}
                    disabled={this.state.yellsDisabled}
                />
            )
        }

        return (
            <>{yells}</>
        )
    }
}

export default YellsList