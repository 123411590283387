import React from "react";
import JanusWrapper from "./common/JanusWrapper";
import CssBaseline from "@material-ui/core/CssBaseline";
import RoomPage from "./room/RoomPage";
import LoginPage from "./login/LoginPage";

import config from "./common/config";

import 'typeface-roboto';

class App extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            roomId : null,
            name : null,
            myId : null,
            myPvtId : null,
            camOn : true,
            micOn : true,
        }

        this.JanusWrapper = new JanusWrapper({
            videoWidth : config.videoResolution.width,
            videoHeight : config.videoResolution.height,
            wssServer : config.wssServer,
            adminServer : config.adminServer,
            adminSecret : config.adminSecret,
        })

        this.roomRedirect = this.roomRedirect.bind(this)
    }

    roomRedirect(roomId, name, myId, myPvtId, camOn, micOn){
        this.setState({
            roomId : roomId,
            name : name,
            myId : myId,
            myPvtId : myPvtId,
            camOn : camOn,
            micOn : micOn,
        })
    }

    isReadyToRedirect(){
        return (this.state.roomId && this.state.name && this.state.myId && this.state.myPvtId)
    }

    render () {
        if (this.isReadyToRedirect()) {
            return (
                <>
                    <CssBaseline />
                    <RoomPage
                        JanusWrapper={this.JanusWrapper}
                        roomId={this.state.roomId}
                        name={this.state.name}
                        myId={this.state.myId}
                        myPvtId={this.state.myPvtId}
                        micOn={this.state.micOn}
                        camOn={this.state.camOn}
                    />
                </>
            )
        } else {
            return (
                <>
                    <CssBaseline />
                    <LoginPage
                        JanusWrapper={this.JanusWrapper}
                        roomRedirect={this.roomRedirect}
                    />
                </>
            )
        }
    }
}

export default App