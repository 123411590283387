import Box from "@material-ui/core/Box";
import React from "react";
import {withStyles} from "@material-ui/core/styles";
import ChatIcon from '@material-ui/icons/Chat';
import Badge from '@material-ui/core/Badge';

import styles from "../RoomStyles";

class ChatToggle extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            counter:0
        }
    }

    chatToggle(e){
        e.preventDefault()
        this.props.chatToggle()

        if (this.props.hidden) {
            this.setState({
                counter:0
            })
        }
    }

    receiveMessage(){
        if (this.props.hidden) {
            this.setState({counter : this.state.counter+1})
        }
    }

    render() {
        return (
            <Box className={this.props.classes.chatIcon}>
                <Badge color="secondary" badgeContent={this.state.counter} max={99}>
                    <ChatIcon
                        fontSize='large'
                        onClick={this.chatToggle.bind(this)}
                        style={{color : this.props.hidden ? 'black' : 'red'}}
                    />
                </Badge>
            </Box>
        );
    }
}

export default withStyles(styles)(ChatToggle)