import config from "../common/config";

const barWidth = 100

const styles = {
    roomRoot: {
        display: 'flex',
    },
    roomContainer: {
        textAlign : 'center',
        height:"100vh",
        minHeight:"100vh",
        display : "flex",
        justifyContent : "center",
        alignItems:"center",
        backgroundColor: '#272c34',
        width:'100%'
    },
    roomLocalVideo: {
        height: Math.ceil(config.videoResolution.height * barWidth / config.videoResolution.width),
        width: barWidth,
        display : "flex",
        justifyContent : "center",
        alignItems : "center",
    },
    roomSideBar : {
        width:barWidth,
        height:'100vh',
        overflowY:'scroll',
        overflowX:'hidden'
    },

    participantsFlexBox : {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'center',
        alignItems:'stretch',
        alignContent:'stretch',
    },

    participantBox : {
        position:'relative',
        flex:'0 0 auto',
    },

    //mic and cam in room page
    controlsBox : {
        display : "flex",
        justifyContent : "center",
        alignItems : "center",
        marginTop:10,
    },
    controlButtonOn : {
        fontSize:"37px",
        cursor: 'pointer'
    },
    controlButtonOff : {
        fontSize:"37px",
        cursor:'pointer',
        color:'red'
    },

    //participant
    participantName : {
        position: 'absolute',
        top: 10,
        left: 10,
        color:'#FFFFFF',
        fontWeight:"bold",
        fontSize:"large"
    },
    participantVideo : {
        display:"flex",
        justifyContent:"center",
        alignItems:"center",
        height:"100%"
    },
    participantStatus : {
        position: 'absolute',
        top: 10,
        right: 10
    },
    participantPopover : {
        padding : '20px'
    },
    participantPopoverBox : {
        position:'absolute',
        width:'100%',
        height:'100%',
        display : "flex",
        justifyContent : "center",
        alignItems:"center",
        zIndex:10,
        top:0,
        left:0,
        fontSize:'30px',
        fontWeight:'bold'
    },

    //actions
    actionButton: {
        width:'90%'
    },
    actionPopover : {
        padding: '8px',
        fontSize: '20px',
        fontWeight: 'bold',
    },
    actionBox : {
        width:'100%',
        display : "flex",
        justifyContent : "center",
        alignItems : "center",
        marginTop:'10px'
    },

    //chat related
    chatBox: {
        width:350,
        height:'100vh',
        position:'absolute',
        right: 90,
        display:'flex',
        flexDirection:'column',
        zIndex:1000,
    },
    chatMessagesList: {
        width:'100%',
        height:'inherit',
        overflow:'scroll',
        display:'flex',
        flexDirection: 'column',
        position:'relative',
    },
    chatForm: {
        width:'100%',
        height:'100px',
        borderTopColor:'grey',
        borderTop:'1px',
        borderTopStyle:'solid',
        marginTop:'15px',
        overflow: 'scroll'
    },
    chatMessage: {
        margin:'15px 15px 0 15px'
    },
    chatMessageName: {
        fontWeight:'bold'
    },
    chatMessageText: {
        marginTop:'3px'
    },
    chatMessageGif: {
        marginTop:'3px',
    },
    chatMessageGifImage: {
        width:'100%',
    },
    chatMessageInput: {
        margin:'0 10px',
        width:'95%',
        height:'100%'
    },
    chatIcon : {
        width: '100%',
        display : "flex",
        justifyContent : "center",
        alignItems:"center",
        cursor:'pointer',
        marginTop:'15px'
    },

    //gif related
    gifBox: {
        width: 425,
        height: '100vh',
        position: 'absolute',
        right: 90,
        overflowY: 'scroll',
        zIndex:1000,
    },
    gifParticipantContainer : {
        width:'100%',
        height:'100%',
        zIndex: 500,
        position:'absolute',
        backgroundColor: 'black'
    },
    gifIconContainer : {
        width: '100%',
        display : "flex",
        justifyContent : "center",
        alignItems:"center",
        cursor:'pointer',
        marginTop:'5px'
    },

    participantMute : {
        position:'absolute',
        width:'50px',
        height:'50px',
        justifyContent : "center",
        alignItems: "center",
        zIndex:10,
        bottom:"1px",
        left:"45%",
        fontSize:'30px',
        fontWeight:'bold',
        cursor:'pointer',
        color: 'white',
    }
}

export default styles