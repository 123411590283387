import config from "../common/config";

const styles = {
    container: {
        display:'flex',
        justifyContent:"center",
        alignItems:"center",
        minHeight:"100vh"
    },
    card: {
        width: config.videoResolution.width
    },
    localVideo: {
        height: config.videoResolution.height,
        width: config.videoResolution.width,
        display : "flex",
        justifyContent : "center",
        alignItems : "center",
        fontSize : '30px',
        fontWeight : 'bold',
    },
    centerContent : {
        display : "flex",
        justifyContent : "center"
    },
    roomsList: {
        width: "70%",
    },
    participantsPopover: {
        padding:'10px'
    }
}

export default styles