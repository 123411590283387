import React from 'react'
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import Box from  '@material-ui/core/Box'
import 'typeface-roboto';
import Divider from "@material-ui/core/Divider";
import RoomsList from "./RoomsList";
import NameInput from "./NameInput";
import LocalVideo from "../common/LocalVideo";
import Storage from "../common/Storage";
import MediaButtons from "./MediaButtons";

import styles from "./LoginStyles";

class LoginPage extends React.Component {
    constructor(props) {
        super(props)
        this.classes = props.classes;

        const storedName = Storage.getName() || ''

        this.state = {
            videoSource : '',
            name : storedName,
            roomId : null,
            myId : null,
            inputEnabled : true,
            serverInit : false,
            joinReady : false,
            micOn : true,
            camOn : true
        }

        this.attachToRoom = this.attachToRoom.bind(this)
        this.handleNameChange = this.handleNameChange.bind(this)
        this.micClick = this.micClick.bind(this)
        this.camClick = this.camClick.bind(this)
    }

    //TODO переименовать коллбэки
    componentDidMount() {
        this.props.JanusWrapper.setCallbacks({
            onLocalVideo : this.injectLocalVideo.bind(this),
            onServerDown : this.handleServerDown.bind(this),
            onAttachToRoomSuccess : this.attachToRoomSuccess.bind(this),
            onServerInitialized : this.serverInitialized.bind(this),
            onConnectionUp : this.webrtcUp.bind(this)
        })

        this.props.JanusWrapper.init()
    }

    componentWillUnmount() {
        this.props.JanusWrapper.dropCallbacks()
    }

    /**
     * Янус инициализировался, можно разблокировать кнопку джойна при условии что соединение установлено
     */
    serverInitialized(){
        this.setState({
            serverInit : true
        })
    }

    injectLocalVideo(stream){
        this.setState({
            videoSource : window.URL.createObjectURL(stream)
        })
    }

    /**
     * Нажание на кнопку джойна в комнату
     * @param roomId
     */
    attachToRoom(roomId) {
        this.setState({
            inputEnabled : false, //выключить форму имени,
            joinReady : false
        })

        //Установка связи с комнатой. Публиковаться начтнет ниже
        this.props.JanusWrapper.attachToRoom(this.state.name, roomId)
    }


    /**
     * коллбэк по успеху джойна в комнату
     * Начинаем паблишить в компнату и становимся видимыми другим
     */
    attachToRoomSuccess(roomId, myId, myPvtId){
        this.setState({
            roomId : roomId,
            myId : myId,
            myPvtId : myPvtId
        })
        this.props.JanusWrapper.startPublishing(this.state.camOn, this.state.micOn) //TODO проброс имени
        Storage.setName(this.state.name)
    }

    /**
     * соедиение установлено и можно уходить в комнату
     */
    webrtcUp(){
        this.props.roomRedirect(
            this.state.roomId,
            this.state.name,
            this.state.myId,
            this.state.myPvtId,
            this.state.camOn,
            this.state.micOn
        )
    }

    handleServerDown(error){
        this.setState({inputEnable:false, joinReady:false})
        this.props.JanusWrapper.destroy()
        if (error['message']) {
            alert(error['message'])
        } else {
            alert(error)
        }
        // window.location.reload(false);
    }

    //TODO что за хуйня?
    handleNameChange(name, valid){
        if (valid) {
            this.setState({
                name : name
            })
        } else {
            this.setState({
                name : name
            })
        }
    }

    micClick(e){
        this.props.JanusWrapper.changeLocalAudioStatus(!this.state.micOn)
        this.setState({
            micOn : !this.state.micOn
        })
    }

    camClick(e){
        this.props.JanusWrapper.changeLocalVideoStatus(!this.state.camOn)
        this.setState({
            camOn : !this.state.camOn
        })
    }

    render() {
        return (
            <Box className={this.classes.container}>
                <Card className={this.classes.card}>
                    <Box className={this.classes.localVideo} position="relative">
                        <LocalVideo src={this.state.videoSource} />
                        <Box position="absolute" bottom="20px" display="flex">
                            <MediaButtons
                                micOn={this.state.micOn}
                                camOn={this.state.camOn}
                                micClick={this.micClick}
                                camClick={this.camClick}
                            />
                        </Box>
                    </Box>
                    <Box className={this.classes.centerContent}>
                        <NameInput
                            className={this.classes.centerContent}
                            inputEnabled={this.state.inputEnabled}
                            handleNameChange={this.handleNameChange}
                            name={this.state.name}
                        />
                    </Box>
                    <Divider variant="middle" />
                    <Box className={this.classes.centerContent}>
                        <RoomsList
                            joinReady={this.state.name && this.state.serverInit}
                            handleRoomJoin={this.attachToRoom}
                            JanusWrapper={this.props.JanusWrapper}
                        />
                    </Box>
                </Card>
            </Box>
        )
    }
}

export default withStyles(styles)(LoginPage)