import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import React from "react";
import TextField from '@material-ui/core/TextField';
import {withStyles} from "@material-ui/core/styles";
import { v4 as uuidv4 } from 'uuid';
import { animateScroll } from "react-scroll";

import styles from "../RoomStyles";
import ReactHowler from "react-howler";

import ChatTexMessage from './ChatTexMessage'
import ChatGifMessage from "./ChatGifMessage";

class Chat extends React.Component {
    constructor(props) {
        super(props);
        this.classes = props.classes

        this.messagesObjects = {}

        this.state = {
            messageText: '',
            messagesList: []
        }

        this.player = null
        this.messageInput = null
    }

    submitMessage(){
        const messageText = this.purifyMessage(this.state.messageText)
        if (!messageText) {
            return
        }

        this.setState({
            messageText : ''
        })

        const messageId = uuidv4()
        this.addTextMessageToList(this.props.myName, messageId, this.state.messageText)
        this.props.onSendChatMessage(messageId, messageText)
    }

    addTextMessageToList(name, messageId, messageText){
        this.messagesObjects[messageId] = <ChatTexMessage
            key={messageId}
            id={messageId}
            name={name}
            text={messageText}
        />

        let messagesList = this.state.messagesList
        messagesList.push(messageId)

        this.setState({
            messagesList : messagesList
        })

        this.player.play()
    }

    addGifMessageToList(name, url){
        const messageId = uuidv4() //TODO issue with future history sync and id's will be different both sides

        this.messagesObjects[messageId] = <ChatGifMessage
            key={messageId}
            id={messageId}
            name={name}
            url={url}
        />

        let messagesList = this.state.messagesList
        messagesList.push(messageId)

        this.setState({
            messagesList : messagesList
        })
    }

    receiveMessage(name, messageId, messageText){
        if (this.messagesObjects[messageId]) {
            return
        }

        this.addTextMessageToList(name, messageId, messageText)
    }

    receiveGif(name, url){
        this.addGifMessageToList(name, url)
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        animateScroll.scrollToBottom({
            containerId: "chatMessagesList",
            duration: 1,
            delay: 0,
            smooth: false,
            isDynamic:true
        });

        this.player.stop()

        if (!this.props.hidden) {
            this.messageInput.focus()
        }
    }

    onKeyUp(e){
        if (e.keyCode === 13 && !e.shiftKey) {
            e.preventDefault()
            this.submitMessage()
        }
    }

    purifyMessage(text){
        return text.trim()
    }

    onChange(e){
        e.preventDefault()
        this.setState({messageText:e.target.value})
    }

    render() {
        return (
            <Paper elevation={10} className={this.classes.chatBox} style={{visibility:this.props.hidden ? 'hidden' : 'visible'}}>
                <ReactHowler
                    src='/message.mp3'
                    playing={false}
                    ref={(ref) => (this.player = ref)}
                />
                <Box id='chatMessagesList' className={this.classes.chatMessagesList}>
                    {Object.values(this.messagesObjects)}
                </Box>
                <Box className={this.classes.chatForm}>
                    <form noValidate>
                        <TextField
                            id='messageInput'
                            label="Сообщение"
                            multiline
                            rows={4}
                            value={this.state.messageText}
                            className={this.classes.chatMessageInput}
                            onKeyUp={this.onKeyUp.bind(this)}
                            onChange={this.onChange.bind(this)}
                            inputRef={el => this.messageInput = el}
                        />
                    </form>
                </Box>
            </Paper>
        )
    }
}

export default withStyles(styles)(Chat)