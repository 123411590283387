const config = {
    videoResolution : {
        width : 740,
        height : 416
    },
    videoFrameRate : 30, //TODO
    wssServer : "wss://zavod.alco.chat:8189/janus",
    adminServer : 'https://zavod.alco.chat:7889/admin',
    adminSecret : 'janusoverlord',
    giphyKey: 'a1gNy4s4bahqnu73glBcHnizmA4q10GY',
    giphyDefaultSearch : 'beer',
    gifReplayNum: 2,
    yellCountdown: 4000 //ms

}

export default config
