class Storage {
    static nameKey = 'name'

    static setName(name) {
        localStorage.setItem(this.nameKey, name)
    }

    static getName(){
        return localStorage.getItem(this.nameKey)
    }
}

export default Storage