import MicIcon from '@material-ui/icons/Mic'
import MicOffIcon from '@material-ui/icons/MicOff'
import VideocamRoundedIcon from '@material-ui/icons/VideocamRounded'
import VideocamOffIcon from '@material-ui/icons/VideocamOff'
import Box from "@material-ui/core/Box";
import React from "react";

import styles from "./RoomStyles";
import {withStyles} from "@material-ui/core/styles";

class Controls extends React.Component {
    render() {
        const camProps = {
            className: this.props.camOn ? this.props.classes.controlButtonOn : this.props.classes.controlButtonOff,
            onClick:this.props.camClick
        }

        const micProps = {
            className: this.props.micOn ? this.props.classes.controlButtonOn : this.props.classes.controlButtonOff,
            onClick:this.props.micClick
        }

        return (
            <Box className={this.props.classes.controlsBox}>
                <Box>
                    {this.props.camOn ? <VideocamRoundedIcon {...camProps}/> : <VideocamOffIcon {...camProps}/>}
                    {this.props.micOn ? <MicIcon {...micProps}/> : <MicOffIcon {...micProps}/>}
                </Box>
            </Box>
        )
    }
}

export default withStyles(styles)(Controls)
