import Box from "@material-ui/core/Box";
import React from "react";
import {withStyles} from "@material-ui/core/styles";
import GifIcon from '@material-ui/icons/Gif';

import styles from "../RoomStyles";

class GifToggle extends React.Component {
    render() {
        return (
            <Box className={this.props.classes.gifIconContainer}>
                <GifIcon
                    fontSize="large"
                    style={{color : this.props.hidden ? 'black' : 'red'}}
                    onClick={this.props.gifToggle}
                />
            </Box>
        );
    }
}

export default withStyles(styles)(GifToggle)