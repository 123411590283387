import React from 'react';
import ActionsConfig from '../common/ActionsConfig'
import {Action} from "./Action";

class ActionsList extends React.Component {
    constructor(props) {
        super(props)

        this.locale = 'ru' //TODO

        this.actionClick = this.actionClick.bind(this)
    }

    actionClick(key, text, on=true){
        this.props.onActionClick(key, text, on)
    }

    render() {
        var actions = []
        for (let [key, action] of Object.entries(ActionsConfig.actions)){
            const conf = action[this.locale]
            actions.push(
                <Action
                    key={key}
                    id={key}
                    type="action"
                    buttonText={conf.button}
                    buttonFinish={conf.buttonFinish}
                    texts={conf.yells}
                    onClick={this.actionClick}
                />
            )
        }

        return (
            <>{actions}</>
        )
    }
}

export default ActionsList