import Paper from "@material-ui/core/Paper";
import Box from '@material-ui/core/Box';
import React from "react";
import {withStyles} from "@material-ui/core/styles";
import VideocamOffIcon from '@material-ui/icons/VideocamOff'

import SmokingRoomsIcon from '@material-ui/icons/SmokingRooms';
import LocalBarIcon from '@material-ui/icons/LocalBar';
import Battery20Icon from '@material-ui/icons/Battery20';
import WcIcon from '@material-ui/icons/Wc';
import FastfoodIcon from '@material-ui/icons/Fastfood';

import ReactHowler from 'react-howler'

import Fade from '@material-ui/core/Fade';
import styles from "./RoomStyles";

import config from "../common/config";

import MicIcon from '@material-ui/icons/Mic'
import MicOffIcon from '@material-ui/icons/MicOff'

class Participant extends React.Component {
    constructor(props) {
        super(props);

        this.classes = props.classes;

        this.yellTimerId = null

        this.gifIterationCount = 0
        this.gifRef = null

        this.state = {
            name : this.props.name,
            video: true,
            audio : true,
            videoSrc : this.props.videoSrc,
            dimensions : this.props.dimensions,

            talking : false,

            yell : null,

            drunk : false,
            piss : false,
            smoke : false,
            refill : false,
            food : false,

            gif : null,

            muteVisible : false
        }
    }

    setDimensions(dimensions){
        this.setState({
            dimensions : dimensions
        })
    }

    handleMediaStatus(video, audio) {
        this.setState({
            video:video,
            audio:audio
        })
    }

    showMute() {
        this.setState({muteVisible:true})
    }

    hideMute() {
        this.setState({muteVisible:false})
    }

    handleGif(url, width, height){
        this.setState({
            gif : {
                url : url,
                width : parseInt(width),
                height: parseInt(height)
            }
        })

        this.startTalking()
    }

    gifIteration(){
        this.gifIterationCount += 1
        if (this.gifIterationCount === config.gifReplayNum) {
            this.hideGif()
        } else {
            this.gifRef.play()
        }
    }

    hideGif() {
        this.setState({gif:null})
        this.gifIterationCount = 0
        this.stopTalking()
    }

    handleAction(action, on, yell=null){
        var state = this.state
        state[action] = on
        this.setState(state)
        if (on) {
            this.handleYell(yell)
        }
    }

    handleYell(yell){
        if (this.yellTimerId) {
            this.clearYellTimeout()
        }

        this.setState({
            yell : yell
        })

        this.yellTimerId = setTimeout(
            this.hideYell.bind(this),
            config.yellCountdown
        )

        this.startTalking()
        this.player.play()
    }

    hideYell() {
        this.clearYellTimeout()
        this.setState({yell:null})
        this.player.stop()
        this.stopTalking()
    }

    clearYellTimeout(){
        clearTimeout(this.yellTimerId)
        this.yellTimerId = null
    }

    startTalking(){
        this.setState({talking:true})
    }

    stopTalking(){
        this.setState({talking:false})
    }

    getName(){
        return this.props.name
    }

    mute(){
        this.props.onMute(this.props.id)
    }

    render (){
        var video = <VideocamOffIcon fontSize="large" style={{fill:'white', fontSize:60}} />
        if (this.state.video && this.state.videoSrc) {
            video = <video
                style={{
                    width:this.state.dimensions.width,
                    height:this.state.dimensions.height,
                }}
                autoPlay
                playsInline
                src={this.state.videoSrc}
            />
        }

        return (
            <Box
                id={this.props.id}
                className={this.classes.participantBox}
                style={{
                    width:this.state.dimensions.width,
                    height:this.state.dimensions.height
                }}
            >
                <Box
                    bgcolor='black'
                    style={{
                        width:this.state.dimensions.width,
                        height:this.state.dimensions.height,
                        borderStyle:'solid',
                        borderColor:'white',
                        borderWidth:this.state.talking ? '2px' : 0
                    }}
                    onMouseEnter={this.showMute.bind(this)}
                    onMouseLeave={this.hideMute.bind(this)}
                >
                    {this.state.gif
                        ?
                        <Box className={this.classes.gifParticipantContainer}>
                            <video height={this.state.dimensions.height} autoPlay onEnded={this.gifIteration.bind(this)} ref={(r)=>this.gifRef=r}>
                                <source src={this.state.gif.url} type='video/mp4'/>
                            </video>
                        </Box>
                        : ''
                    }

                    <Box className={this.classes.participantVideo}>{video}</Box>
                    <Box className={this.classes.participantName}>{this.state.name}</Box>

                    <Box className={this.classes.participantStatus}>
                        {!this.state.audio ? <MicOffIcon color='error' fontSize="large"/> : ''}
                        {this.state.smoke ? <SmokingRoomsIcon color='error' fontSize="large"/> : ''}
                        {this.state.refill ? <Battery20Icon color='error' fontSize="large"/> : ''}
                        {this.state.drunk ? <LocalBarIcon color='error' fontSize="large"/> : ''}
                        {this.state.piss ? <WcIcon color='error' fontSize="large"/> : ''}
                        {this.state.food ? <FastfoodIcon color='error' fontSize="large"/> : ''}
                    </Box>

                    <Box className={this.classes.participantMute} onClick={this.mute.bind(this)} hidden={!this.state.muteVisible}>
                        <MicIcon fontSize="large"/>
                    </Box>

                    <Fade in={this.state.yell} timeout={300}>
                        <Box className={this.classes.participantPopoverBox}>
                            <Paper className={this.classes.participantPopover} elevation={10}>
                                {this.state.yell}
                            </Paper>
                            <ReactHowler
                                src='/yell.mp3'
                                playing={false}
                                ref={(ref) => (this.player = ref)}
                            />
                        </Box>
                    </Fade>
                </Box>
            </Box>
        )
    }
}

export default withStyles(styles)(Participant)
