import Box from "@material-ui/core/Box";
import React from "react";
import Linkify from 'react-linkify';

import styles from "../RoomStyles";
import {withStyles} from "@material-ui/core/styles";

function linkDecorator(decoratedHref, decoratedText, key) {
    return (
        <a href={decoratedHref} key={key} target="_blank" rel="noopener noreferrer">
            {decoratedText}
        </a>
    );
}

function NewLineToBr({children = ""}){
    return children.split('\n').reduce(function (arr,line) {
        return arr.concat(
            <Linkify componentDecorator={linkDecorator}>{line}</Linkify>,
            <br />
        );
    },[]);
}

class ChatTexMessage extends React.Component {
    constructor(props) {
        super(props)
        this.classes = props.classes
    }

    render() {
        return (
            <Box className={this.classes.chatMessage}>
                <Box className={this.classes.chatMessageName}>{this.props.name}</Box>
                <Box className={this.classes.chatMessageText}><NewLineToBr>{this.props.text}</NewLineToBr></Box>
            </Box>
        )
    }
}

export default withStyles(styles)(ChatTexMessage)