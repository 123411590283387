import React from "react";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Link from "@material-ui/core/Link";
import Popover from "@material-ui/core/Popover";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import {withStyles} from "@material-ui/core/styles";

import styles from "./LoginStyles";

class RoomsListItem extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            popoverOpen : false,
            popoverAnchor : null,
            participants : [],
            participantsCount : this.props.count
        }

        this.handleGoClick = this.handleGoClick.bind(this)
        this.showParticipants = this.showParticipants.bind(this)
        this.hideParticipants = this.hideParticipants.bind(this)
    }

    handleGoClick(e){
        e.preventDefault()
        this.props.handleRoomJoin(parseInt(this.props.roomId))
    }

    async showParticipants(e){
        e.persist()
        e.preventDefault()

        const participants = []
        const participantsJson = await this.props.JanusWrapper.getParticipantsList(this.props.roomId)

        if (participantsJson && participantsJson['janus'] === 'success' && participantsJson['response']['participants']) {
            for (let participant of participantsJson['response']['participants']) {
                participants.push(participant.display)
            }
        }

        this.setState({
            popoverOpen : true,
            popoverAnchor : e.nativeEvent.target,
            participants : participants,
            participantsCount : participants.length
        })
    }

    hideParticipants(e){
        e.preventDefault()
        this.setState({
            popoverOpen : false,
            popoverAnchor : null,
        })
    }

    render () {
        return (
            <ListItem key={this.props.roomId}>
                <ListItemText primary={this.props.name}/>
                <ListItemText>
                    <Link
                        color="inherit"
                        underline='hover'
                        onClick={this.showParticipants}
                    >
                        {`${this.state.participantsCount} человек`}
                    </Link>
                </ListItemText>

                <Popover
                    open={this.state.popoverOpen}
                    anchorEl={this.state.popoverAnchor}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                    transformOrigin={{
                        vertical: 'center',
                        horizontal: 'center',
                    }}
                    onClose={this.hideParticipants}
                    PaperProps={{className:this.props.classes.participantsPopover}}
                >
                    {this.state.participants.length > 0
                        ? this.state.participants.map((participant) =>
                            <Box key={participant}><b>{participant}</b></Box>
                        )
                        : <Box>Никого</Box>
                    }
                </Popover>

                <Button
                    variant="contained"
                    color="primary"
                    size='small'
                    disabled={!this.props.joinReady}
                    onClick={this.handleGoClick}
                >
                    Иду
                </Button>
            </ListItem>
        )
    }
}

export default withStyles(styles)(RoomsListItem)