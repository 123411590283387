import React from 'react'
import MicIcon from '@material-ui/icons/Mic'
import MicOffIcon from '@material-ui/icons/MicOff'
import VideocamRoundedIcon from '@material-ui/icons/VideocamRounded'
import VideocamOffIcon from '@material-ui/icons/VideocamOff'
import Box from "@material-ui/core/Box";

class MediaButtons extends React.Component {
    render () {

        const contProps = {
            borderColor: 'white',
            border: 1,
            style: { width: '4rem', height: '4rem' },
            display:"flex",
            justifyContent:"center",
            alignItems:"center",
            borderRadius:"50%",
        };

        const iconProps = {
            fontSize : "large",
            style : {fill:'white', cursor:'pointer'}
        };

        var micIcon = null
        var camIcon = null

        if (this.props.micOn) {
            micIcon = <MicIcon {...iconProps} />
        } else {
            micIcon = <MicOffIcon {...iconProps} />
        }

        if (this.props.camOn) {
            camIcon = <VideocamRoundedIcon {...iconProps} />
        } else {
            camIcon = <VideocamOffIcon {...iconProps} />
        }

        return (
            <>
                <Box {...contProps} marginRight="10px" onClick={this.props.camClick}>
                    {camIcon}
                </Box>
                <Box {...contProps} marginLeft="10px" onClick={this.props.micClick}>
                    {micIcon}
                </Box>
            </>
        )
    }
}

export default MediaButtons