import React from "react";
import List from '@material-ui/core/List';
import { withStyles } from '@material-ui/core/styles';

import styles from "./LoginStyles";
import RoomsListItem from "./RoomsListItem";

class RoomsList extends React.Component {
    constructor(props) {
        super(props)
        this.classes = props.classes;
        this.state = {
            rooms: []
        }
    }

    async componentDidMount() {
        const roomsJson = await this.props.JanusWrapper.getRoomsList()
        const roomsState = []

        if (roomsJson && roomsJson['janus'] === 'success' && roomsJson['response']['list']) {
            for (let room of roomsJson['response']['list']) {
                roomsState.push({
                    'roomId' : room['room'],
                    'name' : room['description'],
                    'count' : room['num_participants']
                })
            }
        }

        this.setState({rooms : roomsState})
    }

    render () {
        return (
            <List component="nav" className={this.classes.roomsList}>
                {this.state.rooms.map((room) =>
                    <RoomsListItem
                        key={room.roomId}
                        roomId={room.roomId}
                        name={room.name}
                        count={room.count}
                        joinReady={this.props.joinReady}
                        handleRoomJoin={this.props.handleRoomJoin}
                        JanusWrapper={this.props.JanusWrapper}
                    />
                )}
            </List>
        )
    }
}

export default withStyles(styles)(RoomsList)